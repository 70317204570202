import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import {
  Section,
  BreadCrumb,
  WriterWrap,
  Avatar,
  Writer,
  Triangle,
  SectionPageTitle,
  MarkdownContent,
  Container,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import QuotationMark from "../components/Icons/QuotationMark"
import VirticalArrowIcon from "../components/Icons/VirticalArrowIcon"
import ItemHover from "../components/ItemHover"
import BreakpointUp from "../components/Media/BreakpointUp"

const HeroGrid = styled.div`
  display: flex;
  flex-wrap:wrap;
  margin:0 -15px 45px -15px;
  align-items: center;
  justify-content: space-between;  
`
const HeroLeftGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  ${BreakpointUp.lg`
    flex:0 0 58.333333%;
    max-width:58.333333%;
  `}
`
const HeroRightGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  ${BreakpointUp.lg`
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  `}
`
const HeroDesc = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -10px;
`
const HeroIcon = styled.div`
  position:relative;
  padding:0 10px;
  display: none;
  ${BreakpointUp.xl`
    display: flex;
  `}
  & svg {
    width: 120px;
    height: 100px;
  }
`
const HeroTitle = styled.div`
  position:relative;
  padding:0 10px;
`
const HeroLead = styled.p`
`
const FilterWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  > label{
    min-width:80px;
    margin-bottom:0;
  }
  > span {
    position: relative;
    font-size: 14px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    color: #666;
    line-height: 24px;
  }
`
const FilterDropButton = styled.div`
  margin-left: 10px;
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid #dedede;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  ${BreakpointUp.lg`
    padding: 20px 30px;
    border-radius: 40px;
  `}
  ${BreakpointUp.xl`
    padding: 24px 40px;
    border-radius: 80px;
  `}
  .text {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
  }
  .icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    & svg {
      width: 10px;
      height: 12px;
      margin: 0;
    }
  }
`
const FilterDropDown = styled.div`
  position: absolute;
  border-radius: 8px;
  right: 0;
  z-index: 10;
  display: ${(props) => props.display};
  padding: 20px;
  width: 250px;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  top: 60px;
  ${BreakpointUp.lg`
    top: 80px;
  `}
  `
const FilterItem = styled.div`
font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  margin: 5px 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
const DesktopReviews = styled.div`
  display: block;
  @media (max-width: 1200px) {
    display: none;
  }
`
const TabletReviews = styled.div`
  display: none;
  grid-gap: 30px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ReviewsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Column = styled.div`
  flex: 33.33%;
  max-width: 33.33%;
  padding-left: ${(props) => props.pl};
  padding-right: ${(props) => props.pr};
`
const ReviewCard = styled.div`
  position: relative;
  border: ${(props) => props.border};
  border-radius: 8px;
  background: ${(props) => props.bg};
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
  padding: 30px;
  ${BreakpointUp.xl`
		margin-bottom:30px;
		padding: 50px;
	`}
  & p {
    font-style: Italic;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
  & svg {
    position: absolute;
    width: 48px;
    height: 40px;
    top: -20px;
    left: 50px;
    display: ${(props) => props.display};
  }
`
class Review extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reviews: this.props.data.allContentfulTestimonial,
      currentFilter: "All Reviews",
    }
    this.renderReviewGrid = this.renderReviewGrid.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
  }

  handleFilter(data) {
    this.setState({
      currentFilter: data,
    })
  }

  renderReviewGrid(data) {
    const length = data.length
    const numOfColumn = Math.floor(length / 3)
    const flag = length % 3
    if (flag === 0) {
      return (
        <ReviewsGrid>
          <Column pr="20px">
            {data.map((item, i) => {
              if (i < numOfColumn) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
          <Column pr="10px" pl="10px">
            {data.map((item, i) => {
              if (numOfColumn <= i && i < 2 * numOfColumn) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
          <Column pl="20px">
            {data.map((item, i) => {
              if (2 * numOfColumn <= i && i < length) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
        </ReviewsGrid>
      )
    } else if (flag === 1) {
      return (
        <ReviewsGrid>
          <Column pr="20px">
            {data.map((item, i) => {
              if (i <= numOfColumn) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
          <Column pr="10px" pl="10px">
            {data.map((item, i) => {
              if (i > numOfColumn && i < 2 * numOfColumn + 1) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
          <Column pl="20px">
            {data.map((item, i) => {
              if (i > 2 * numOfColumn && i < length) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
        </ReviewsGrid>
      )
    } else if (flag === 2) {
      return (
        <ReviewsGrid>
          <Column pr="20px">
            {data.map((item, i) => {
              if (i <= numOfColumn) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
          <Column pl="10px" pr="10px">
            {data.map((item, i) => {
              if (i > numOfColumn && i < 2 * numOfColumn + 2) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
          <Column pl="20px">
            {data.map((item, i) => {
              if (i > 2 * numOfColumn + 1 && i < length) {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <h2 className="author">{item.node.customerName}</h2>
                        <span className="role text-muted">{item.node.customerRole}</span>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              }
            })}
          </Column>
        </ReviewsGrid>
      )
    }
  }
  render() {
    const pageData = this.props.data.contentfulTestimonialListPage
    const { reviews, currentFilter } = this.state
    const filter = [
      "All Reviews",
      "Electronic Repair",
      "Mechanical Repair",
      "Servo Repair",
      "Hydraulic Repair",
      "Imaging Repair",
    ]
    let reviewsData = []
    if (currentFilter === "All Reviews") {
      reviews.edges.forEach(function (edge, index) {
        reviewsData.push(edge)
      })
    } else {
      reviews.edges.forEach(function (edge, index) {
        if (currentFilter === edge.node.category) {
          reviewsData.push(edge)
        }
      })
    }
    const schema = {
      "@context": "https://schema.org/", 
      "@type": "Rating", 
      "name": "AES Electronic Services", 
      "aggregateRating": { 
      "@type": "AggregateRating", 
      "ratingValue": "4.2", 
      "bestRating": "5", 
      "ratingCount": "19" 
      }
    }
    return (
      <Layout>
        <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} schemaMarkup={schema}/>
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpt="120px" xpb="60px" mpt="120px" mpb="60px" pt="90px" pb="30px" bgColor="#F5F5F5">
          <Container>
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link> Review
            </BreadCrumb>
            <HeroGrid>
              <HeroLeftGrid>
                <HeroDesc>
                  <HeroIcon>
                    <QuotationMark fill="#FAB702" />
                  </HeroIcon>
                  <HeroTitle>
                    <SectionPageTitle mb="0">{pageData.title}</SectionPageTitle>
                    <HeroLead className="h4">{pageData.description.description}</HeroLead>
                  </HeroTitle>
                </HeroDesc>
              </HeroLeftGrid>
              <HeroRightGrid>
                <FilterWrap>
                  <label>Filter By:</label>
                  <ItemHover>
                    {(hoverItem) => (
                      <div>
                        <FilterDropButton
                          bg={hoverItem ? "#FAB702" : "white"}
                          color={hoverItem ? "white" : "#666666"}
                        >
                          <span className="text">{currentFilter}</span>
                          <span className="icon">
                            <VirticalArrowIcon
                              fill={hoverItem ? "white" : "rgba(0,0,0,.7)"}
                            />
                          </span>
                        </FilterDropButton>
                        <FilterDropDown display={hoverItem ? "block" : "none"}>
                          <Triangle top="-20px" bg="white" />
                          {filter.map((item, i) => {
                            return (
                              <FilterItem
                                key={i}
                                onClick={() => this.handleFilter(item)}
                              >
                                {item}
                              </FilterItem>
                            )
                          })}
                        </FilterDropDown>
                      </div>
                    )}
                  </ItemHover>
                </FilterWrap>
              </HeroRightGrid>
            </HeroGrid>
            <DesktopReviews>
              {this.renderReviewGrid(reviewsData)}
            </DesktopReviews>
            <TabletReviews>
              {reviewsData.map((item, i) => {
                return (
                  <ReviewCard
                    bg={i % 2 === 0 ? "#FFFFFF" : "#FFFAEF"}
                    border={i % 2 === 0 ? "none" : "1px solid #FAB702"}
                    display={i % 2 === 0 ? "none" : "block"}
                  >
                    <QuotationMark fill="#FAB702" />
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.node.review.childMarkdownRemark.html,
                      }}
                    />
                    <WriterWrap>
                      <Avatar>
                        <GatsbyImage
                          image={getImage(item.node.customerImage)}
                          alt="AES"
                        />
                      </Avatar>
                      <Writer>
                        <strong>{item.node.customerName}</strong>
                        <p>{item.node.customerRole}</p>
                      </Writer>
                    </WriterWrap>
                  </ReviewCard>
                )
              })}
            </TabletReviews>
          </Container>
        </Section>
        <EmergencyEstimateCTA
          leftData={pageData.emergencyCta}
          rightData={pageData.estimateCta}
        />
      </Layout>
    )
  }
}

export default Review

export const pageQuery = graphql`
  query ReviewPageQuery {
    contentfulTestimonialListPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      description {
        description
      }
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
    allContentfulTestimonial(sort: { fields: createdAt }) {
      edges {
        node {
          customerName
          customerRole
          customerImage {
            gatsbyImageData(quality: 100)
          }
          review {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
